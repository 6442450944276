export interface Item {
  [key: string]: string | number;
}

export const arraysAreEqual = (a: Array<number | string>, b: Array<number | string>) => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

export const setsAreEqual = (xs: Set<number | string>, ys: Set<number | string>) =>
  xs.size === ys.size && [...xs].every((x) => ys.has(x));

// Sort array based on a reference array
export const customSortArray = <T>(arrayToSort: T[], referenceArray: Array<number | string>, key: string): T[] => {
  return [...arrayToSort].sort(
    (a, b) =>
      referenceArray.indexOf(a[key as keyof T] as number | string) -
      referenceArray.indexOf(b[key as keyof T] as number | string),
  );
};

type FilterFunction = (item: number | string) => boolean;

export const arraysContainSameValues = (
  a: Array<number | string>,
  b: Array<number | string>,
  filters?: FilterFunction[],
) => {
  if (filters) {
    a = a.filter((item) => !filters.every((filter) => filter(item)));
    b = b.filter((item) => !filters.every((filter) => filter(item)));
  }

  return a.length === b.length && a.every((val) => b.includes(val));
};
