import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { date, object, ref } from 'yup';

import CustomDropdown from '@/components/custom-dropdown/CustomDropdown';
import NavBlocker from '@/components/view-editor/NavBlocker';
import ViewLayoutGroupBy from '@/components/view-editor/ViewLayoutGroupBy';
import UIConfig from '@/config/ui.config';
import { ISO_8601_DATE_FORMAT } from '@/constants/time';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewEditorDraftThemeData } from '@/store/slices/viewEditor.slice';
import { DaysOfWeekAbbreviationFromFullString, DaysOfWeekFullStringFromAbbreviation } from '@/types/ui.types';
import { ViewLayoutBlockInclusive, ViewLayoutBlockLengthDays, ViewLayoutBlockWeekLength } from '@/types/view.types';

interface ViewLayoutBlockProps {
  displayRange: string;
  groupBy: string;
  handleDisplayRangeChange: (value: string) => void;
  handleGroupByChange: (value: string) => void;
}

interface DateFieldProps {
  field: {
    name: string;
    value: string;
  };
  form: {
    errors: { [field: string]: string };
    touched: { [field: string]: boolean };
  };
}

const selectedTabStyle = {
  color: 'white',
};

const CURRENT_ISO_DATE = DateTime.now().toFormat(ISO_8601_DATE_FORMAT);

const defaultBlockLayout: ViewLayoutBlockInclusive = {
  blockAnchorDate: CURRENT_ISO_DATE,
  blockLength: '1',
  blockStart: DaysOfWeekAbbreviationFromFullString.Monday,
  blockStaticStart: CURRENT_ISO_DATE,
  blockStaticStop: CURRENT_ISO_DATE,
  blockViewTotalWeeks: 1,
  blockWeekLength: '1',
};

enum ViewLayoutBlockRange {
  ANCHORED = 'anchored',
}

const ViewLayoutBlockTotalWeeksComponentConfig = {
  defaultValue: 1,
  maxValue: 52,
  minValue: 1,
};

const datePickerSchema = object({
  startDate: date().required('Start date is required').max(ref('stopDate'), 'Start date must be before stop date'),
  stopDate: date().required('Stop date is required').min(ref('startDate'), 'Stop date must be after start date'),
});

const ViewLayoutBlock = (props: ViewLayoutBlockProps): React.JSX.Element => {
  const { displayRange, groupBy, handleDisplayRangeChange, handleGroupByChange } = props;

  const blockRangeIndex = Object.values(ViewLayoutBlockRange).findIndex((i: string) => i === displayRange);

  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);
  const dispatch = useAppDispatch();

  const {
    blockAnchorDate = defaultBlockLayout.blockAnchorDate,
    blockLength = defaultBlockLayout.blockLength,
    blockStart = defaultBlockLayout.blockStart,
    blockStaticStart = defaultBlockLayout.blockStaticStart,
    blockStaticStop = defaultBlockLayout.blockStaticStop,
    blockViewTotalWeeks = defaultBlockLayout.blockViewTotalWeeks,
    blockWeekLength = defaultBlockLayout.blockWeekLength,
  } = view.theme.data ?? {};

  const blockProps: ViewLayoutBlockInclusive = {
    blockAnchorDate,
    blockLength,
    blockStart,
    blockStaticStart,
    blockStaticStop,
    blockViewTotalWeeks,
    blockWeekLength,
  };

  // const blockProps: ViewLayoutBlockInclusive = React.useMemo(
  //   () => ({
  //     blockAnchorDate,
  //     blockLength,
  //     blockStart,
  //     blockStaticStart,
  //     blockStaticStop,
  //     blockViewTotalWeeks,
  //     blockWeekLength,
  //   }),
  //   [blockAnchorDate, blockLength, blockStart, blockStaticStart, blockStaticStop, blockViewTotalWeeks, blockWeekLength],
  // );

  const [tabIndex, setTabIndex] = React.useState(blockRangeIndex);

  const initAnchorView = () => {
    dispatch(
      setViewEditorDraftThemeData({
        blockAnchorDate: blockAnchorDate,
        blockLength: blockProps.blockLength,
        blockStart: blockProps.blockStart,
        blockViewTotalWeeks: blockProps.blockViewTotalWeeks ?? UIConfig.DEFAULT_BLOCK_LENGTH_WEEKS,
        blockWeekLength: blockProps.blockWeekLength,
        range: ViewLayoutBlockRange.ANCHORED,
      }),
    );
  };

  // initialize data block for anchored view on load
  React.useEffect(() => {
    if (!view.theme.data?.blockViewTotalWeeks) {
      initAnchorView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initStaticView = () => {
    dispatch(
      setViewEditorDraftThemeData({
        blockLength: blockProps.blockLength,
        blockStart: blockProps.blockStart,
        blockStaticStart: blockProps.blockStaticStart,
        blockStaticStop: blockProps.blockStaticStop,
        blockWeekLength: blockProps.blockWeekLength,
      }),
    );
  };

  const handleTabSelectorChange = (index: number) => {
    const rangeType = Object.values(ViewLayoutBlockRange)[index];

    setTabIndex(index);

    if (rangeType === ViewLayoutBlockRange.ANCHORED) {
      initAnchorView();
      return;
    }

    initStaticView();

    handleDisplayRangeChange(rangeType);
  };

  const getTabSelector = (label: string, values: string[], onChange: (index: number) => void) => {
    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <Text>{label}</Text>
        <Tabs variant={'unstyled'} position={'relative'} onChange={onChange} index={tabIndex}>
          <TabList>
            {values.map((value) => (
              <Tab key={value} _selected={selectedTabStyle}>
                {_.capitalize(value)}
              </Tab>
            ))}
          </TabList>
          <TabIndicator
            bg={'blue.500'}
            borderRadius={'10px'}
            height={'40px'}
            mt={'-40px'}
            zIndex={-1}
            position={'inherit'}
            textColor={'white'}
          />
        </Tabs>
      </VStack>
    );
  };

  const getDateInput = (label: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, value: string) => {
    return (
      <VStack justifyContent={'space-between'} align={'left'}>
        <FormLabel>{label}</FormLabel>
        <Input type={'date'} size={'md'} w={'200px'} onChange={onChange} value={value} />
      </VStack>
    );
  };

  const getStaticRangeDateInputs = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{ startDate: blockStaticStart, stopDate: blockStaticStop }}
        onSubmit={() => {
          return;
        }}
        validationSchema={datePickerSchema}
      >
        {({ setFieldValue, touched, validateForm }) => (
          <Form>
            <HStack gap={'5'} align={'baseline'}>
              <Field name="startDate">
                {({ field, form }: DateFieldProps) => (
                  <FormControl isRequired={true} isInvalid={(form.errors.startDate && touched.startDate) as boolean}>
                    <VStack justifyContent={'space-between'} align={'left'}>
                      <FormLabel>{'Block Start Date'}</FormLabel>
                      <Input
                        {...field}
                        type={'date'}
                        size={'md'}
                        w={'200px'}
                        value={field.value}
                        onChange={(e) => {
                          setFieldValue('startDate', e.target.value).then(() => {
                            validateForm().then((errors) => {
                              if (!errors.startDate) {
                                dispatch(setViewEditorDraftThemeData({ blockStaticStart: e.target.value }));
                              }
                            });
                          });
                        }}
                      />
                      <FormErrorMessage>{form.errors.startDate}</FormErrorMessage>
                    </VStack>
                  </FormControl>
                )}
              </Field>
              <Field name="stopDate">
                {({ field, form }: DateFieldProps) => (
                  <FormControl isRequired={true} isInvalid={(form.errors.stopDate && touched.stopDate) as boolean}>
                    <VStack justifyContent={'space-between'} align={'left'}>
                      <FormLabel>{'Block Stop Date'}</FormLabel>
                      <Input
                        {...field}
                        type={'date'}
                        size={'md'}
                        w={'200px'}
                        value={field.value}
                        onChange={(e) => {
                          setFieldValue('stopDate', e.target.value).then(() => {
                            validateForm().then((errors) => {
                              if (!errors.stopDate) {
                                dispatch(setViewEditorDraftThemeData({ blockStaticStop: e.target.value }));
                              }
                            });
                          });
                        }}
                      />
                      <FormErrorMessage>{form.errors.stopDate}</FormErrorMessage>
                    </VStack>
                  </FormControl>
                )}
              </Field>
            </HStack>
            <NavBlocker />
          </Form>
        )}
      </Formik>
    );
  };

  const setViewTotalWeeks = (value: number) => {
    // Bail to prevent re-rendering if value is the same
    if (isNaN(value) || value === blockProps.blockViewTotalWeeks) return;

    if (view.theme.data.blockViewTotalWeeks) {
      dispatch(
        setViewEditorDraftThemeData({
          blockViewTotalWeeks: value,
        }),
      );
    }
  };

  const handleDecreaseViewTotalWeeks = (weeks: number) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    const step = weeks - Number.parseInt(blockProps.blockWeekLength);

    setViewTotalWeeks(_.clamp(step, minValue, maxValue));
  };

  const handleIncreaseViewTotalWeeks = (weeks: number) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    // step changes according to the week length
    const step = Number.parseInt(blockProps.blockWeekLength) + weeks;

    setViewTotalWeeks(_.clamp(step, minValue, maxValue));
  };

  const handleChangeViewTotalWeeks = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxValue, minValue } = ViewLayoutBlockTotalWeeksComponentConfig;
    const value = parseInt(e.target.value);

    setViewTotalWeeks(_.clamp(value, minValue, maxValue));
  };

  const getAnchoredRangeDateInputs = () => {
    return (
      <>
        {getDateInput(
          'Block Start Date',
          (e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(setViewEditorDraftThemeData({ blockAnchorDate: e.target.value })),
          blockProps.blockAnchorDate,
        )}
        <VStack align={'left'}>
          <Text>Block View Total Weeks</Text>
          <HStack>
            <Button onClick={() => handleDecreaseViewTotalWeeks(blockProps.blockViewTotalWeeks)}>
              <FaMinus />
            </Button>
            <Input
              maxW={'60px'}
              value={blockProps.blockViewTotalWeeks}
              onChange={handleChangeViewTotalWeeks}
              max={ViewLayoutBlockTotalWeeksComponentConfig.maxValue}
              min={ViewLayoutBlockTotalWeeksComponentConfig.minValue}
              textAlign={'center'}
            />
            <Button onClick={() => handleIncreaseViewTotalWeeks(blockProps.blockViewTotalWeeks)}>
              <FaPlus />
            </Button>
          </HStack>
        </VStack>
      </>
    );
  };

  const setBlockLength = (length: ViewLayoutBlockLengthDays) => {
    if (view.theme.data.blockLength) {
      dispatch(
        setViewEditorDraftThemeData({
          blockLength: length,
        }),
      );
    }
  };

  const setWeekLength = (length: ViewLayoutBlockWeekLength) => {
    if (view.theme.data.blockWeekLength) {
      dispatch(
        setViewEditorDraftThemeData({
          blockViewTotalWeeks: length,
          blockWeekLength: length,
        }),
      );
    }
  };

  const setStartOnDay = (day: DaysOfWeekAbbreviationFromFullString) => {
    if (view.theme.data.blockStart) {
      dispatch(
        setViewEditorDraftThemeData({
          blockStart: day,
        }),
      );
    }
  };

  return (
    <VStack align={'left'} justifyContent={'space-between'} gap={'5'}>
      <HStack alignItems={'center'} gap={'5'}>
        {getTabSelector('Range', Object.values(ViewLayoutBlockRange), handleTabSelectorChange)}
      </HStack>
      <HStack gap={'5'}>
        {displayRange === ViewLayoutBlockRange.ANCHORED ? getAnchoredRangeDateInputs() : getStaticRangeDateInputs()}
      </HStack>
      <HStack justifyContent={'space-between'} w={'200px'} gap={'5'}>
        <CustomDropdown
          label={'Block Start Day'}
          options={Object.keys(DaysOfWeekAbbreviationFromFullString)}
          onChange={(e) =>
            setStartOnDay(
              DaysOfWeekAbbreviationFromFullString[e.target.value as keyof typeof DaysOfWeekAbbreviationFromFullString],
            )
          }
          selectedOption={DaysOfWeekFullStringFromAbbreviation[blockProps.blockStart]}
        />
        <CustomDropdown
          label={'Block Length (Days)'}
          options={UIConfig.BLOCK_LENGTH_DAYS}
          onChange={(e) => setBlockLength(e.target.value as ViewLayoutBlockLengthDays)}
          selectedOption={blockProps.blockLength}
        />
      </HStack>
      <HStack justifyContent={'space-between'} w={'200px'} gap={'5'}>
        <CustomDropdown
          label={'Block Week Length'}
          options={UIConfig.BLOCK_LENGTH_WEEKS}
          onChange={(e) => setWeekLength(e.target.value as ViewLayoutBlockWeekLength)}
          selectedOption={blockProps.blockWeekLength}
        />
        <ViewLayoutGroupBy onChange={handleGroupByChange} selected={groupBy} />
      </HStack>
    </VStack>
  );
};

export default ViewLayoutBlock;
